<template>
  <footer class="footer">
    <div class="base-container-first footer__container typography__body1">
      <ul class="footer__content">
        <li>
          <TheFooterLogo />
        </li>

        <li>
          <TheFooterDonutEmail />
        </li>

        <TheFooterSocial />
      </ul>

      <ul class="footer__content typography__body1">
        <li
          v-for="(link, linkIndex) in LINKS"
          :key="linkIndex"
        >
          <BaseLink
            class="footer__content-link"
            :href="link.href"
          >
            {{ link.text }}
          </BaseLink>
        </li>
      </ul>

      <ul class="footer__content typography__body1">
        <li
          v-for="(term, termIndex) in TERMS"
          :key="termIndex"
        >
          <BaseLink
            class="footer__content-term"
            :href="term.href"
          >
            {{ term.text }}
          </BaseLink>
        </li>

        <li>
          <TheFooterCopyright />
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup lang="ts">
const LINKS = [
  {
    href: "/rates",
    text: "Тарифы",
  },
  {
    href: "/questions",
    text: "Поддержка",
  },
  {
    href: "/contacts",
    text: "Контакты",
  },
];

const TERMS = [
  {
    href: "/about/user-agreement",
    text: "Пользовательское соглашение",
  },
  {
    href: "/about/privacy-policy",
    text: "Политика конфиденциальности",
  },
  {
    href: "/about/cookie",
    text: "Использование cookie",
  },
  {
    href: "/about/license-agreement",
    text: "Лицензионный договор",
  },
];
</script>

<style scoped>
.footer {
  display: flex;
  color: var(--grey-1-5);
}

.footer__container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

@media (--desktop) {
  .footer__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--hover) {
  .footer__content-link:hover {
    color: var(--orange-3);
  }
}

.footer__content-term {
  color: var(--grey-3);
}

@media (--hover) {
  .footer__content-term:hover {
    color: var(--grey-2);
  }
}
</style>
