<template>
  <div class="app typography__body2">
    <TheHeader/>

    <main class="app__main ui-scrollbar-primary">
      <slot/>
    </main>

    <TheFooter/>

    <TheCookie/>
  </div>

  <LazyAuthRegistrationModal
    v-if="isModalOpen(MODAL.AuthRegistration)"
    :id="MODAL.AuthRegistration"
  />

  <LazyAuthLoginModal
    v-if="isModalOpen(SHARED_MODAL.AuthLogin)"
    :id="SHARED_MODAL.AuthLogin"
  />

  <LazyAuthPasswordNewModal
    v-if="isModalOpen(MODAL.AuthPasswordNew)"
    :id="MODAL.AuthPasswordNew"
  />

  <LazyAuthPasswordRemindModal
    v-if="isModalOpen(MODAL.AuthPasswordReset)"
    :id="MODAL.AuthPasswordReset"
  />
</template>

<script setup lang="ts">
const {isModalOpen} = useModalStore();

useHead({
  script: [
    {
      async: true,
      src: "//code.jivo.ru/widget/7Slof3XiwX",
    },
  ],
});
</script>

<style scoped>
@media (--desktop) {
  .app {
    display: grid;
  }
}

@media (--desktop) {
  .app__main {
    overflow: auto;
  }
}
</style>
